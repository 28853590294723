import axios from 'axios';
import moment from 'moment';
import momenttz from 'moment-timezone';
import FileDownload from 'js-file-download';
import * as config from '../config';

export const SETISLOADINGSCENES = 'scenes/SETISLOADINGSCENES';
export const SETSCENES = 'scenes/SETSCENES';

export const getScenes = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({ type: SETISLOADINGSCENES, isLoadingScenes: true });

    axios(`${config.endpoints.base_url}scenes`).then((resp) => {
      dispatch({ type: SETISLOADINGSCENES, isLoadingScenes: false });
      dispatch({ type: SETSCENES, scenes: transformScenes(resp.data.data.rows, getState) });
      // dispatch({ type: SETSCENES, scenes: [] });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

const transformScenes = (scenes, getState) => {
  return scenes.map(scene => {
    const regions = scene.regions.map((region) => {
      const tags = region.tags.map((tag) => {
        return {
          ...tag,
          value: tag.id,
          label: tag.name,
        }
      });
      return {
        ...region,
        tags
      }
    })
    return {
      ...scene,
      regions
    }
  });
};

export const transformRegions = (regions) => {
  return regions.map((region) => {
    const tags = region.tags.map((tag) => {
      return {
        ...tag,
        value: tag.id,
        label: tag.name,
      }
    });
    return {
      ...region,
      tags
    }
  })
};

export const addScene = ( { scene }, onSuccess, onError) => {
  return (dispatch, getState) => {

    const retransformedScene = retransformScene(scene);

    axios.post(
      `${config.endpoints.base_url}scenes`,
      retransformedScene
    ).then((resp) => {
      return onSuccess(resp);
    }).catch((error) => {
      return onError();
    });

  };
};

export const editScene = ( { id, scene }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    const retransformedScene = retransformScene(scene);

    axios.put(
      `${config.endpoints.base_url}v3/scenes/${id}`,
      retransformedScene
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const deleteScene = ( { id }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    axios.delete(
      `${config.endpoints.base_url}scenes/${id}`,
      {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      }
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const REQUESTSCENEFRAMESTART = 'scenes/REQUESTSCENEFRAMESTART';
export const REQUESTSCENEFRAMEEND = 'scenes/REQUESTSCENEFRAMEEND';

export const requestSceneFrameFromDevice = ({ sceneID }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    dispatch({ type: REQUESTSCENEFRAMESTART, sceneID });

    axios.post(
      `${config.endpoints.base_url}v1/scenes/${sceneID}/frame`
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const requestSceneFrameFromDeviceAxis = ({ sceneID }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    dispatch({ type: REQUESTSCENEFRAMESTART, sceneID });

    axios.post(
      `${config.endpoints.base_url}v1/scenes/${sceneID}/frame/axis`
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};


export const cancelSceneFrameFromDevice = ({ sceneID, frameToken }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    dispatch({ type: REQUESTSCENEFRAMESTART, sceneID });

    axios.post(
      `${config.endpoints.base_url}v1/scenes/${sceneID}/frame/${frameToken}`,
      { status: 'Cancelled' }
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

const retransformScene = (scene) => {

  return {
    name: scene.name,
    description: scene.name,
    feeder_id: parseInt(scene.feeder_id, 10),
    assigned_to: parseInt(scene.assigned_to, 10),
    usecase: ''
  };
};

export const getSceneByID = (sceneID) => {
  return (dispatch, getState) => {
    if(getState().scenes.scenes) {
      const scene = getState().scenes.scenes.find((sceneItem) => {
        return sceneItem.id === parseInt(sceneID, 10);
      });
      if(scene) {
        return scene;
      }
    }
    return null;
  };
};

export const getScenesBySiteID = (siteID) => {
  return (dispatch, getState) => {
    if(getState().scenes.scenes) {
      const scenes = getState().scenes.scenes.filter((sceneItem) => {
        return sceneItem.site_id === parseInt(siteID, 10);
      });
      if(scenes) {
        return scenes;
      }
    }
    return null;
  };
};

export const SETISLOADINGCRITICALSCENE = 'scenes/SETISLOADINGCRITICALSCENE';
export const SETCRITICALSCENE = 'scenes/SETCRITICALSCENE';

export const getCriticalScene = ({ incident_start_time = moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss'), incident_end_time = moment().format('YYYY-MM-DD HH:mm:ss') }, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({ type: SETISLOADINGCRITICALSCENE, isLoadingCriticalScene: true });
    axios(`${config.endpoints.base_url}scenes/critical?incident_start_time=${incident_start_time}&incident_end_time=${incident_end_time}&timezone=${momenttz.tz.guess()}`).then((resp) => {
      dispatch({ type: SETISLOADINGCRITICALSCENE, isLoadingCriticalScene: false });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const setCriticalScene = (criticalScene) => {
  return (dispatch, getState) => {
    dispatch({ type: SETCRITICALSCENE, criticalScene });
  };
};

export const SETISLOADINGSCENEEVENTS = 'scenes/SETISLOADINGSCENEEVENTS';
export const SETSCENEEVENTS = 'scenes/SETSCENEEVENTS';

export const clearSceneEvents = () => {
  return (dispatch, getState) => {
    dispatch({ type: SETSCENEEVENTS, sceneEvents: [] });
  };
};


export const getSceneEvents = ({start_time = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), end_time = moment().format('YYYY-MM-DD HH:mm:ss'), scene_id = null}, onSuccess = () => {}, onError = () => {}, limit = 0) => {
  return (dispatch, getState) => {

    dispatch({ type: SETSCENEEVENTS, sceneEvents: [] });
    dispatch({ type: SETISLOADINGSCENEEVENTS, isLoadingSceneEvents: true   });

    // end_time = moment(start_time).endOf('day').format('YYYY-MM-DD HH:mm:ss');

    axios(`${config.endpoints.base_url}data/sceneevents?start_time=${start_time}&end_time=${end_time}&scene_id=${scene_id}&timezone=${momenttz.tz.guess()}${limit > 0 ? `&limit=${limit}` : ``}`).then((resp) => {
      dispatch({ type: SETISLOADINGSCENEEVENTS, isLoadingSceneEvents: false });
      dispatch({ type: SETSCENEEVENTS, sceneEvents: resp.data.data.rows });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const SETSCENEVIEWDATETIME = 'scenes/SETSCENEVIEWDATETIME';

export const setSceneViewDateTime = (date = moment().format(), time = moment().format()) => {
  return (dispatch, getState) => {
    dispatch({ type: SETSCENEVIEWDATETIME, sceneViewDate: date.format(), sceneViewTime: time.format() });
  };
};

export const SETSCENEVIEWTIMEEVENT = 'scenes/SETSCENEVIEWTIMEEVENT';

export const setSceneViewTimeEvent = (sceneViewTimeEvent = {}) => {
  return (dispatch, getState) => {
    dispatch({ type: SETSCENEVIEWTIMEEVENT, sceneViewTimeEvent });
  };
};

export const SETISLOADINGINCIDENTS = 'scenes/SETISLOADINGINCIDENTS';

export const getIncidents = ({ start_time = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), end_time = moment().format('YYYY-MM-DD HH:mm:ss') }, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({ type: SETISLOADINGINCIDENTS, isLoadingIncidents: true });

    axios(`${config.endpoints.base_url}incidents?start_time=${start_time}&end_time=${end_time}&timezone=${momenttz.tz.guess()}`).then((resp) => {
      dispatch({ type: SETISLOADINGINCIDENTS, isLoadingIncidents: false });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const getOpenIncidents = ({ scene_id = undefined, incident_start_time = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), incident_end_time = moment().format('YYYY-MM-DD HH:mm:ss') }, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    axios(`${config.endpoints.base_url}incidents?&start_time=${incident_start_time}&end_time=${incident_end_time}${scene_id ? `&scene_id=${scene_id}` : ''}&timezone=${momenttz.tz.guess()}`).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });

  };
};

export const updateIncidentStatus = (id, incidentParams, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    axios.post(
      `${config.endpoints.base_url}v1/incidents/${id}/events`,
      incidentParams
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export  const INCIDENTSTATUS = {
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  BEINGPROCESSED: 'Being processed',
  CANCELLED: 'Cancelled'
};

export const INCIDENTTYPE = {
  CONTACT_THRESHOLD: 'contact_threshold',
  PROXIMITY_THRESHOLD: 'proximity_threshold',
  CLEANUPTIME_THRESHOLD: 'cleanuptime_threshold'
};

export const SETISLOADINGREGIONS = 'scenes/SETISLOADINGREGIONS';
export const SETREGIONS = 'scenes/SETREGIONS';

export const getRegions = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({ type: SETISLOADINGREGIONS, isLoadingRegions: true });

    axios(`${config.endpoints.base_url}regions`).then((resp) => {
      dispatch({ type: SETISLOADINGSCENES, isLoadingRegions: false });
      dispatch({ type: SETREGIONS, regions: resp.data.data.rows });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const getRegionByID = (region_id) => {
  return (dispatch, getState) => {
    if(getState().scenes.regions) {
      const region = getState().scenes.regions.find((regionItem) => {
        return regionItem.id === parseInt(region_id, 10);
      });
      if(region) {
        return region;
      }
    }
    return null;
  };
};

export const ARTYPES = {
  AR: 1,
  TRIPLINE: 0,
  ZONES: 2,
  MASKING: 3,
  INCLUSION: 4,
  DIRECTION: 5,
  DESTINATION: 6,
  CORNERPINS: 7,
  DISTANCE: 8
};

export const addRegion = ( { region }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    const retransformedRegion = retransformRegion(region);

    axios.post(
      `${config.endpoints.base_url}regions`,
      retransformedRegion
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const editRegion = ( { id, region }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    const retransformedRegion = retransformRegion(region);

    axios.put(
      `${config.endpoints.base_url}regions/${id}`,
      retransformedRegion
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const removeRegion = ( { id }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    axios.delete(
      `${config.endpoints.base_url}regions/${id}`
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

const retransformRegion = (region) => {

  return {
    name: region.name,
    description: region.name,
    scene_id: region.scene_id,
    features: region.features,
    points: region.points,
    color_hm: '',
    contact_threshold: region.contact_threshold,
    proximity_threshold: region.proximity_threshold,
    cleanuptime_threshold: region.cleanuptime_threshold,
    cleanuptime_unit_threshold: 'min',
    assigned_to: 2,
    preset_id: null,
    sla_time: region.sla_time,
    sla_time_unit: 'min',
  };
};

export const SETISLOADINGSTAFFS = 'scenes/SETISLOADINGSTAFFS';
export const SETSTAFFS = 'scenes/SETSTAFFS';

export const getStaffs = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({ type: SETISLOADINGSTAFFS, isLoadingStaffs: true });

    axios(`${config.endpoints.base_url}staffs`).then((resp) => {
      dispatch({ type: SETISLOADINGSTAFFS, isLoadingStaffs: false });
      dispatch({ type: SETSTAFFS, staffs: resp.data.data.rows });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const getStaffByID = (staffID) => {
  return (dispatch, getState) => {
    if(getState().scenes.staffs) {
      const staff = getState().scenes.staffs.find((staffItem) => {
        return staffItem.id === parseInt(staffID, 10);
      });
      if(staff) {
        return staff;
      }
    }
    return null;
  };
};

export const addStaff = ( { staff }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    const retransformedStaff = retransformStaff(staff);

    axios.post(
      `${config.endpoints.base_url}staffs`,
      retransformedStaff
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const editStaff = ( { id, staff }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    const retransformedStaff = retransformStaff(staff);

    axios.put(
      `${config.endpoints.base_url}staffs/${id}`,
      retransformedStaff
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const removeStaff = ( { id }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    axios.delete(
      `${config.endpoints.base_url}staffs/${id}`
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

const retransformStaff = (staff) => {
  return {
    first_name: staff.first_name,
    middle_name: null,
    last_name: null,
    suffix: null,
    phone_number: staff.phone_number,
    email: staff.email,
    position: 'staff',
    site_id: staff.site_id
  };
};

export const getOverrideDateTime = (hash, index, defaultDateTime) => {
  if(hash.length > 0) {
    if( decodeURI(hash.substr(1).split('/')[index]) ) {
      return moment(decodeURI(hash.substr(1).split('/')[index]));
    }
    return defaultDateTime;
  }
  return defaultDateTime;
};

export const CONTACT_MATRIX = {
  MIN: 0,
  MAX: 50,
  RADIUS: 8,
  MAX_THRESHOLD: 1000,
  BLUR: .75,
  MINOPACIY: 0.1,
  MAXOPACITY: 1,
  GRADIENT: {
    '0.01': '#ffa23f',
    '0.10': '#ffa23f',
    '0.11': '#f27d00',
    '0.25': '#f27d00',
    '0.26': '#ff7f00',
    '0.40': '#ff7f00',
    '0.41': '#F73700',
    '0.50': '#F73700',
    '0.51': '#D21500',
    '0.75': '#D21500',
    '0.76': '#A30C00',
    '1.00': '#A30C00'
  }
};

export const PROXIMITY_MATRIX = {
  MIN: 0,
  MAX: 255,
  RADIUS: 8,
  MAX_THRESHOLD: 2000,
  BLUR: .75,
  MINOPACIY: 0.1,
  MAXOPACITY: 0.5,
  GRADIENT: {
    '0.01': '#ffa23f',
    '0.10': '#ffa23f',
    '0.11': '#f27d00',
    '0.25': '#f27d00',
    '0.26': '#ff7f00',
    '0.40': '#ff7f00',
    '0.41': '#F73700',
    '0.50': '#F73700',
    '0.51': '#D21500',
    '0.75': '#D21500',
    '0.76': '#A30C00',
    '1.00': '#A30C00'
  }
};

export const getScenesSummary = ({ start_time = moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss'), end_time = moment().format('YYYY-MM-DD HH:mm:ss') }, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    axios(`${config.endpoints.base_url}insights/scenes/summary?&start_time=${start_time}&end_time=${end_time}&timezone=${momenttz.tz.guess()}`).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const getIncidentsManifest = ({ start_time = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), end_time = moment().format('YYYY-MM-DD HH:mm:ss') }, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({ type: SETISLOADINGINCIDENTS, isLoadingIncidents: true });

    axios(`${config.endpoints.base_url}insights/incidents/list?&start_time=${start_time}&end_time=${end_time}&timezone=${momenttz.tz.guess()}`).then((resp) => {
      dispatch({ type: SETISLOADINGINCIDENTS, isLoadingIncidents: false });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const getIncidentByID = ({ id }, onSuccess, onError) => {
  axios(`${config.endpoints.base_url}incidents/${id}`).then((resp) => {
    return onSuccess(resp.data);
  }).catch((err) => {
    onError(err);
  });
};

const fiveMinutes = 5;

export const nearestRoundedMinutes = (interval = fiveMinutes, toRoundMoment) => {
  const roundedMinutes = Math.round((toRoundMoment.clone().minute() + toRoundMoment.clone().second() / 60) / interval) * interval;

  return toRoundMoment.clone().minute(roundedMinutes).second(0);
};

export const downloadCleanupManifestCSV = (startTime = moment().format('YYYY-MM-DD HH:mm:ss'), endTime = moment().format('YYYY-MM-DD HH:mm:ss'), onSuccess, onError) => {
  axios({
    url: `${config.endpoints.base_url}insights/incidents/list?start_time=${startTime}&end_time=${endTime}&timezone=${momenttz.tz.guess()}`,
    method: 'GET',
    headers: {
      'Accept': 'text/csv'
    }
  }).then((resp) => {
    FileDownload(resp.data, 'cleanupmanifest.csv');
  }).catch((err) => {
    onError(err);
  });
};

const retransformSceneWithRegions = (scene) => {
  return {
    ...scene
  }
};

export const saveServiceAppletSceneRequest = (scene) => {
  const retransformedSceneWithRegions = retransformSceneWithRegions(scene);
  return axios.put(
    `${config.endpoints.base_url}v2/scenes/${scene.id}`,
    retransformedSceneWithRegions
  );
};

export const setLocalARToGlobalAR = (activeRegion) => {
  return axios.post(
    `${config.endpoints.base_url}/regions/${activeRegion.id}/global`
  );
};

export const setGlobalARToLocalAR = (activeRegion) => {
  return axios.post(
    `${config.endpoints.base_url}/regions/${activeRegion.id}/local`
  );
};

export const getGlobalARs = () => {
  return axios.get(
    `${config.endpoints.base_url}/regions?is_global=true`
  );
};

export const getScenesWithGlobalARID = (globalARID) => {
  return axios.get(
    `${config.endpoints.base_url}/scenes?is_global=true&region_id=${globalARID}`
  );
};

const retransformGroup = (group) => {
  return {
    name: group.name
  }
};

export const getGroups = () => {
  return axios.get(
    `${config.endpoints.base_url}/groups`
  );
};

export const addGroup = (group) => {
  const retransformedGroup = retransformGroup(group);
  return axios.post(
    `${config.endpoints.base_url}/groups`,
    retransformedGroup
  );
};

export const updateGroup = (group) => {
  const retransformedGroup = retransformGroup(group);
  return axios.put(
    `${config.endpoints.base_url}/groups/${group.id}`,
    retransformedGroup
  );
};

export const removeGroup = (group) => {
  return axios.delete(
    `${config.endpoints.base_url}/groups/${group.id}`
  );
};

export const getAppsWithOverlaps = (networkID) => {
  return axios.get(
    `${config.endpoints.base_url}/v2/networks/${networkID}/apps?has_overlaps=1`
  );
};

export const getSceneOverlaps = (sceneID) => {
  return axios.get(
    `${config.endpoints.base_url}/v2/scenes/${sceneID}/overlaps`
  );
};

export const getScenesOverlaps = (sceneID, sceneToID, appID) => {
  return axios.get(
    `${config.endpoints.base_url}/v2/scenes/${sceneID}/overlaps?scene_from=${sceneID}&scene_to=${sceneToID}&app_id=${appID}`
  );
};

export const addSceneOverlaps = (sceneID, main_sensor_id, overlaps) => {
  return axios.post(
    `${config.endpoints.base_url}/v2/scenes/${sceneID}/overlaps`,
    {
      main_sensor_id,
      overlaps
    }
  );
};

export const updateSceneOverlaps = (sceneID, main_sensor_id, overlaps) => {
  return axios.put(
    `${config.endpoints.base_url}/v2/scenes/${sceneID}/overlaps`,
    {
      main_sensor_id,
      overlaps
    }
  );
};

export const getSceneCornerPins = (feederID) => {
  return axios.get(
    `${config.endpoints.base_url}v4/feeders/${feederID}/pins`
  );
};

export const addSceneCornerPins = (feederID, cornerPins) => {
  return axios.post(
    `${config.endpoints.base_url}v4/feeders/${feederID}/pins`,
    { corner_pins: cornerPins }
  );
};

export const updateSceneCornerPins = (feederID, cornerPins) => {
  return axios.put(
    `${config.endpoints.base_url}v4/feeders/${feederID}/pins`,
    {
      corner_pins: cornerPins
    }
  );
};

export const updateSceneCornerPinsWithOrientation = (feederID, cornerPins, sensorOrientation) => {
  return axios.put(
    `${config.endpoints.base_url}v4/feeders/${feederID}/pins`,
    {
      corner_pins: cornerPins,
      sensor_orientation: sensorOrientation
    }
  );
};

export const getFlattenScene = (sceneID, data) => {
  return axios.post(
    `${config.endpoints.base_url}/v2/scenes/${sceneID}/linear/preview`,
    {
      ...data
    }
  );
};

export const updateFlattenScene = (sceneID, data) => {
  return axios.put(
    `${config.endpoints.base_url}/v2/scenes/${sceneID}/linear`,
    {
      config: {...data}
    }
  );
};
